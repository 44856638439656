// App.tsx

import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './layout/autoeization/AuthContext';
import ProtectedRoute from './layout/Protect/ProtectedRoute';
import { PurchasedItemsProvider } from './page/paymentsPages/PurchasedItemsContext';
import { UserProvider } from './components/telegram/UserContext';
import { useCrisp } from './components/hooks/useCrisp';
import 'react-toastify/dist/ReactToastify.css';

// Импортируем наш спиннер
import { Spinner } from './components/spiner/Spinner';

// Ленивая загрузка модулей (страниц):
const Shop = lazy(() => import('./page/shop'));
const AdminPanel = lazy(() => import('./page/AdminPanel'));
const LoginComponent = lazy(() => import('./layout/UserAuteriztion/UserCart'));
const PaymentSuccess = lazy(() => import('./page/paymentsPages/PaymentSuccess'));
const FlowTelegramAuth = lazy(() => import('./components/telegram/FlowTelegramAuth'));

export function App() {
  // Инициализация Crisp через хук
  useCrisp("63e2c9a9-fa2b-4c85-a14f-f0f7b0c0e313");

  return (
    <AuthProvider>
      <PurchasedItemsProvider>
        <UserProvider>
          <Suspense fallback={<Spinner />}>
            <FlowTelegramAuth />
            <Routes>
              <Route path="/" element={<Shop />} />
              <Route path="/auth" element={<LoginComponent />} />
              <Route path="/reset-password/:token" element={<LoginComponent />} />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute
                    element={<AdminPanel />}
                    allowedRoles={['admin', 'courier']}
                  />
                }
              />
              <Route path="/payment/success" element={<PaymentSuccess />} />
            </Routes>
          </Suspense>
        </UserProvider>
      </PurchasedItemsProvider>
    </AuthProvider>
  );
}

export default App;