import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

// Подключаем ТУТ ваш файл i18n:
import './i18n';  // <-- Обязательно обратить внимание, что здесь уже лежит наша обновлённая конфигурация

import App from './App';
import { GlobalStyled } from './styles/globalStyled';
import { CartProvider } from './layout/basket/CartContext';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/Theme';
import './scripts/telegram-web-app.js';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppWrapper = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  // Telegram WebApp готовность
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
    }
  }, []);

  return (
    <>
      <GlobalStyled isOpen={isOpen} />
      <Router>
        <ThemeProvider theme={theme}>
          <CartProvider>
            <App />
          </CartProvider>
        </ThemeProvider>
      </Router>
    </>
  );
};

// Регистрация Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js') // Убедитесь, что `sw.js` находится в public/
    .then((registration) => {
      console.log('Service Worker зарегистрирован:', registration);
    })
    .catch((error) => {
      console.error('Ошибка при регистрации Service Worker:', error);
    });
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
      <AppWrapper />
    </GoogleOAuthProvider>
  </React.StrictMode>
);
