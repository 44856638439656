// components/Spinner.tsx
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Анимация полного вращения
const spinFull = keyframes`
  0% {
    transform: rotate(0deg) translateZ(0);
  }
  100% {
    transform: rotate(360deg) translateZ(0);
  }
`;

// Общий контейнер для лоадера
const Loader = styled.div`
  position: fixed; /* чтобы занимал весь экран */
  top: 0;
  left: 0;
  width: 100vw;   
  height: 100vh;  
  display: flex;  
  align-items: center;  
  justify-content: center;
  background: radial-gradient(
    ellipse at center,
    rgba(245, 247, 250, 1) 0%,
    rgba(230, 233, 237, 1) 100%
  );
  z-index: 9999; /* Поверх всех элементов */
  font-size: 32px;
`;

// Общий стиль для "лепестков" (item-1, item-2 и т.д.)
const LoaderItem = styled.div`
  position: absolute;
  will-change: transform;

  &.item-1 {
    margin-left: 11.313708496px;
    margin-top: -11.313708496px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(45deg) translateZ(0);
    }
  }

  &.item-2 {
    margin-left: 0px;
    margin-top: -16px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(180deg) translateZ(0);
    }
  }

  &.item-3 {
    margin-left: -11.313708496px;
    margin-top: -11.313708496px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(135deg) translateZ(0);
    }
  }

  &.item-4 {
    margin-left: -16px;
    margin-top: 0px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(270deg) translateZ(0);
    }
  }

  &.item-5 {
    margin-left: -11.313708496px;
    margin-top: 11.313708496px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(225deg) translateZ(0);
    }
  }

  &.item-6 {
    margin-left: 0px;
    margin-top: 16px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(360deg) translateZ(0);
    }
  }

  &.item-7 {
    margin-left: 11.313708496px;
    margin-top: 11.313708496px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(315deg) translateZ(0);
    }
  }

  &.item-8 {
    margin-left: 16px;
    margin-top: 0px;
    animation: ${spinFull} 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    span {
      transform: rotate(450deg) translateZ(0);
    }
  }
`;

// Отдельный стиль для вложенного span
const ItemSpan = styled.span`
  display: block;
  width: 0.5em;
  height: 0.15em;
  background: black;
  border-radius: 0.08em;
`;

export const Spinner: React.FC = () => (
  <Loader>
    <LoaderItem className="item-1">
      <ItemSpan />
    </LoaderItem>
    <LoaderItem className="item-2">
      <ItemSpan />
    </LoaderItem>
    <LoaderItem className="item-3">
      <ItemSpan />
    </LoaderItem>
    <LoaderItem className="item-4">
      <ItemSpan />
    </LoaderItem>
    <LoaderItem className="item-5">
      <ItemSpan />
    </LoaderItem>
    <LoaderItem className="item-6">
      <ItemSpan />
    </LoaderItem>
    <LoaderItem className="item-7">
      <ItemSpan />
    </LoaderItem>
    <LoaderItem className="item-8">
      <ItemSpan />
    </LoaderItem>
  </Loader>
);