// src/hooks/useCrisp.ts
import { useEffect } from 'react';

export function useCrisp(crispWebsiteId: string) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).$crisp = [];
      (window as any).CRISP_WEBSITE_ID = crispWebsiteId;

      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.head.appendChild(s);
    }
  }, [crispWebsiteId]);
}
