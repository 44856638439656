import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import socket from '../../socket';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  address?: string;
  phone?: string;
  role: string;
}

export interface AuthContextType {
  user: User | null;
  // Делаем accessToken и refreshToken опциональными
  login: (user: User, accessToken?: string, refreshToken?: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (storedUser && accessToken) {
      try {
        const parsedUser: User = JSON.parse(storedUser);
        setUser(parsedUser);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        socket.emit('login', parsedUser.id);
      } catch (err) {
        console.error('Ошибка при парсинге user из localStorage:', err);
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
    }

    setLoading(false);
  }, []);

  const setupCrispUser = (user: User) => {
    if (window.$crisp) {
      if (user.email) {
        window.$crisp.push(["set", "user:email", [user.email]]);
      }
      window.$crisp.push(["set", "user:nickname", [`${user.first_name} ${user.last_name}`]]);
      if (user.phone) {
        window.$crisp.push(["set", "user:phone", [user.phone]]);
      }
      window.$crisp.push(["set", "session:data", [[["user_id", user.id.toString()]]]]);
    }
  };

  const login = (user: User, accessToken?: string, refreshToken?: string) => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));

    // Если accessToken передан – используем его, иначе ничего не ставим
    if (accessToken) {
      localStorage.setItem('access_token', accessToken);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      // Если нет токена, то удаляем существующий
      localStorage.removeItem('access_token');
      delete axios.defaults.headers.common['Authorization'];
    }

    // Если refreshToken передан – сохраняем его
    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken);
    } else {
      localStorage.removeItem('refresh_token');
    }

    socket.emit('login', user.id);
    setupCrispUser(user);
  };

  const logout = () => {
    if (user) {
      socket.emit('logout', user.id);
    }
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete axios.defaults.headers.common['Authorization'];
    navigate('/');

    if (window.$crisp) {
      window.$crisp.push(["do", "session:reset", []]);
    }
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth должен использоваться внутри AuthProvider');
  }
  return context;
};
